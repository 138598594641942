import React from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSelect } from '@elastic/eui'

import { SegmentTarget } from 'api'

export interface IAcSegmentLogicItem {
  segment: SegmentTarget
  segmentOptions: SegmentTarget[]
  onDelete?: () => void
  onSegmentChanged?: (segmentId: number) => void
}

export const AcSegmentLogicItem: React.FC<IAcSegmentLogicItem> = props => {
  const onDelete = () => {
    props.onDelete?.()
  }

  const onChange = (segmentId: string) => {
    if (segmentId) {
      props.onSegmentChanged?.(parseInt(segmentId))
    }
  }

  return (
    <EuiPanel hasShadow={false} hasBorder={false} style={{ width: 400 }} paddingSize={'none'}>
      <EuiFlexGroup gutterSize={'s'}>
        <EuiFlexItem>
          <EuiSelect options={props.segmentOptions.map(o => ({ value: o.id.toString(), text: o.name }))} value={props.segment.id} onChange={e => onChange(e.target.value)} aria-label='Segment Option' />
        </EuiFlexItem>
        {props.onDelete && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon color={'danger'} onClick={onDelete} iconType='cross' aria-label='remove' />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPanel>
  )
}

import React from 'react'

import { EuiLoadingSpinner } from '@elastic/eui'

import { CuratedAudience } from 'api/entities/CuratedAudience'
import { useGetCuratedAudiencesQuery } from 'api/rtkQueryApi/opsApi/audiencesApi'
import { AcTargeterDetailed, IAcTargeterDetailedItem } from 'components/Targeters/AcTargeterDetailed'

export interface ICuratedAudienceTargeter {
  curatedAudienceIds: string[]
  onAudienceRemove: (item: IAcTargeterDetailedItem) => void
}

export const CuratedAudienceTargeter: React.FC<ICuratedAudienceTargeter> = props => {
  const audienceQuery = useGetCuratedAudiencesQuery({ query: '' })
  const [filteredAudiences, setFilteredAudiences] = React.useState<CuratedAudience[]>([])

  React.useEffect(() => {
    setFilteredAudiences(audienceQuery.data?.filter(a => props.curatedAudienceIds.some(id => id === a.id)) ?? [])
  }, [audienceQuery.data, props.curatedAudienceIds])

  const onAudienceRemove = (audience: IAcTargeterDetailedItem) => {
    props.onAudienceRemove?.(audience)
  }

  if (audienceQuery.isLoading) {
    return <EuiLoadingSpinner />
  }

  return (
    <AcTargeterDetailed
      items={
        filteredAudiences.map(r => ({
          key: r.id.toString(),
          name: r.name,
          description: r.description ?? ''
        })) ?? []
      }
      onItemRemoved={onAudienceRemove}
    />
  )
}

import numeral from 'numeral'
import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiSpacer, EuiStat, EuiTitle } from '@elastic/eui'

import { ThirdPartyAudience, Zip } from 'api'
import { ZipFinder } from 'components/Finders'
import { AudienceFinder } from 'components/Finders/AudienceFinder'
import { AcCreateOrganizationModal } from 'components/Modals/AcCreateOrganizationModal'
import { AudienceTargeterDetailed } from 'components/Targeters'
import TargetingMap from 'components/TargetingMap'
import { BrandMetricsWidget } from 'features/tools/tools/BrandMetricsWidget'
import history from 'services/HistoryService'

export const ToolsTab: React.FC = () => {
  const [selectedAudiences, setSelectedAudiences] = React.useState<ThirdPartyAudience[]>([])
  const [selectedZip, setSelectedZip] = React.useState<Zip>()
  const [showNewOrganizationModal, setShowNewOrganizationModal] = React.useState<boolean>(false)

  const onNewOrganizationClick = () => {
    setShowNewOrganizationModal(true)
  }

  const onNewOrganizationClose = () => {
    setShowNewOrganizationModal(false)
  }

  const onOrgCreated = (orgId: string) => {
    setShowNewOrganizationModal(false)
    history.push(`/customers/organizations/${orgId}`)
  }

  return (
    <React.Fragment>
      <EuiButtonEmpty iconType={'plus'} size={'s'} onClick={onNewOrganizationClick}>
        New Organization
      </EuiButtonEmpty>
      <EuiSpacer />
      <EuiTitle size={'xs'}>
        <h2>Audience Builder</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiStat
            title={
              (selectedAudiences.length > 0
                ? numeral(
                    selectedAudiences.reduce((a, b) => {
                      return a + b.reachCount
                    }, 0)
                  ).format('0a')
                : 0) + ' people'
            }
            description='Aggregate Audience Size'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow fullWidth>
        <AudienceFinder
          onAudienceClicked={audience => {
            setSelectedAudiences([...selectedAudiences, audience])
          }}
          isInvalid={false}
        />
      </EuiFormRow>
      <EuiFormRow>
        <AudienceTargeterDetailed
          audiences={selectedAudiences}
          onAudienceRemoved={audience => {
            setSelectedAudiences(selectedAudiences.filter(a => a.id !== audience.id))
          }}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFormRow fullWidth>
        <BrandMetricsWidget />
      </EuiFormRow>
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFormRow label='Zip Finder'>
        <ZipFinder
          addZip={zip => {
            setSelectedZip(zip)
          }}
          isInvalid={false}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow label={'Billboard Map'} fullWidth>
        <TargetingMap hideDrawingTools hideLocationSearch suggestedPlaces={selectedZip ? [selectedZip.name] : []} includeBillboards geoCircles={[]} addCircle={() => {}} removeCircle={() => {}} modifyCircle={() => {}} geoRectangles={[]} addRectangle={() => {}} removeRectangle={() => {}} modifyRectangle={() => {}} geoPolygons={[]} addPolygon={() => {}} removePolygon={() => {}} modifyPolygon={() => {}} />
      </EuiFormRow>
      {showNewOrganizationModal && <AcCreateOrganizationModal onClose={onNewOrganizationClose} onOrgCreated={onOrgCreated} />}
    </React.Fragment>
  )
}

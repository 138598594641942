import React from 'react'

import { EuiIcon, EuiListGroup, EuiSpacer } from '@elastic/eui'

export interface IAcTargeterDetailedItem {
  key: string
  name: string
  description: string
}

export interface IAcTargeterDetailed {
  items: IAcTargeterDetailedItem[]
  onItemRemoved: (item: IAcTargeterDetailedItem) => void
}

export const AcTargeterDetailed: React.FC<IAcTargeterDetailed> = props => {
  const onItemRemoved = (item: IAcTargeterDetailedItem) => {
    props.onItemRemoved(item)
  }

  return (
    <React.Fragment>
      {props.items.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s'>
          {props.items.map(item => (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 5px' }} key={item.key}>
              <div>
                <b>{item.name}</b>
                <EuiSpacer size={'s'} />
                <div style={{ paddingLeft: '10px', fontSize: '0.8em' }}>{item.description}</div>
              </div>
              <div style={{ minWidth: '150px', display: 'flex', justifyContent: 'end' }}>
                <EuiIcon type='cross' onClick={() => onItemRemoved(item)} />
              </div>
            </div>
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}

import React, { useEffect } from 'react'

import { EuiFormRow } from '@elastic/eui'

import { useGetCuratedAudiencesQuery } from 'api/rtkQueryApi/opsApi/audiencesApi'
import { AcSearchSelector, IAcSearchSelectorItem } from 'components/ACTools/AcSearchSelector'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface ICuratedAudienceTargeter {
  onCuratedAudienceClicked: (item: IAcSearchSelectorItem) => void
}

export const CuratedAudienceFinder: React.FC<ICuratedAudienceTargeter> = props => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('')
  const [searchTerm, setSearchTerm] = React.useState('')
  const [displayedAudiences, setDisplayedAudiences] = React.useState<IAcSearchSelectorItem[]>([])

  const curatedAudiencesQuery = useGetCuratedAudiencesQuery({ query: debouncedSearchTerm })

  useDebounceEffect(
    500,
    () => {
      setDebouncedSearchTerm(searchTerm)
    },
    [searchTerm]
  )

  useEffect(() => {
    setDisplayedAudiences(curatedAudiencesQuery.data?.filter(a => a.name.toLowerCase().includes(debouncedSearchTerm) || a.description.toLowerCase().includes(debouncedSearchTerm)).map(a => ({ key: a.id.toString(), label: a.name, description: a.description })) || [])
  }, [curatedAudiencesQuery.data, debouncedSearchTerm])

  const onCuratedAudienceClicked = (item: IAcSearchSelectorItem) => {
    props.onCuratedAudienceClicked(item)
  }

  const onSearchChange = (value: string) => {
    setSearchTerm(value)
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth label={'Curated Audience'}>
        <AcSearchSelector searchTerm={searchTerm} onSearchChange={onSearchChange} onItemClicked={onCuratedAudienceClicked} isLoading={curatedAudiencesQuery.isLoading || curatedAudiencesQuery.isFetching} items={displayedAudiences} />
      </EuiFormRow>
    </React.Fragment>
  )
}

import React from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer } from '@elastic/eui'

import { SegmentOperator } from 'api'

export interface IAcSegmentOperatorItem {
  operator: SegmentOperator
  isNot: boolean
  onOperatorChanged?: (operator: SegmentOperator) => void
  onAddSegmentClick?: () => void
  onAddLogicGroupClick?: () => void
  onNotClick?: () => void
  hideAddGroup?: boolean
  hideOperator?: boolean
  hideIsNot?: boolean
}

export const AcSegmentOperatorItem: React.FC<IAcSegmentOperatorItem> = props => {
  const onChange = (operator: string) => {
    if (operator) {
      props.onOperatorChanged?.(operator as SegmentOperator)
    }
  }

  const onAddSegmentClick = () => {
    props.onAddSegmentClick?.()
  }

  const onAddLogicGroupClick = () => {
    if (props.hideAddGroup) {
      return
    }
    props.onAddLogicGroupClick?.()
  }

  const onNotClick = () => {
    props.onNotClick?.()
  }

  return (
    <EuiPanel hasShadow={false} hasBorder={false} style={{ width: 180 }} paddingSize={'none'} color={props.isNot ? 'danger' : undefined}>
      {!props.hideOperator && (
        <React.Fragment>
          <EuiFormRow fullWidth>
            <EuiSelect options={Object.keys(SegmentOperator).map(o => ({ value: o, text: o }))} value={props.operator} onChange={e => onChange(e.target.value)} aria-label='Operator Option' />
          </EuiFormRow>
          <EuiSpacer size={'s'} />
        </React.Fragment>
      )}
      <EuiFlexGroup gutterSize={'s'}>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon color={'success'} onClick={onAddSegmentClick} iconType='plus' aria-label='Add Segment' title={'Add Segment'} />
        </EuiFlexItem>
        {!props.hideAddGroup && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon color={'success'} onClick={onAddLogicGroupClick} iconType='layers' aria-label='Add Group' title={'Add Group'} />
          </EuiFlexItem>
        )}
        {!props.hideIsNot && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon color={'danger'} onClick={onNotClick} iconType={props.isNot ? 'eyeClosed' : 'eye'} aria-label='Include / Exclude' title={'Include / Exclude'} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPanel>
  )
}

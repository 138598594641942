import React, { useEffect } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import { SegmentLogic, SegmentOperator, SegmentTarget } from 'api'
import { AcSegmentLogicItem } from 'components/ACTools/AcSegmentLogicItem'
import { AcSegmentOperatorItem } from 'components/ACTools/AcSegmentOperatorItem'

export interface ISegmentGroup {
  segmentLogic: SegmentLogic
  segmentOptions: SegmentTarget[]
  onLogicChanged?: (segmentLogic: SegmentLogic) => void
  maxDepth?: number
  depth?: number
  disableOperator?: boolean
  disableIsNot?: boolean
}

interface SegmentRow {
  key: string
  segment: SegmentTarget
}

export const SegmentGroup: React.FC<ISegmentGroup> = props => {
  const [segments, setSegments] = React.useState<SegmentTarget[]>([])
  const [segmentRows, setSegmentRows] = React.useState<SegmentRow[]>([])

  useEffect(() => {
    const segs: SegmentTarget[] = []
    const rows: SegmentRow[] = []
    props.segmentLogic.segmentIds.forEach(segmentId => {
      const segment = props.segmentOptions.find(s => s.id === segmentId)
      if (segment) {
        segs.push(segment)
        rows.push({ key: rows.length.toString(), segment })
      }
    })
    setSegmentRows(rows)
    setSegments(segs)
  }, [props.segmentOptions, props.segmentLogic])

  const onSegmentRemoved = (rowKey: string) => {
    const newSegmentIds = segmentRows.filter(row => row.key !== rowKey).map(row => row.segment.id)
    props.onLogicChanged?.({ ...props.segmentLogic, segmentIds: newSegmentIds })
  }

  const onSegmentTargetChanged = (rowKey: string, newId: number) => {
    const newSegmentIds = segmentRows.map(row => (row.key === rowKey ? newId : row.segment.id))
    props.onLogicChanged?.({ ...props.segmentLogic, segmentIds: newSegmentIds })
  }

  const onOperatorChanged = (operator: SegmentOperator) => {
    props.onLogicChanged?.({ ...props.segmentLogic, operator })
  }

  const onAddLogicGroupClick = () => {
    if (props.segmentOptions.length > 0) {
      props.onLogicChanged?.({ ...props.segmentLogic, logicGroups: [...props.segmentLogic.logicGroups, { operator: SegmentOperator.And, segmentIds: [props.segmentOptions[0].id], logicGroups: [], isNot: false }] })
    }
  }

  const onAddSegmentClick = () => {
    if (props.segmentOptions.length > 0) {
      props.onLogicChanged?.({
        ...props.segmentLogic,
        segmentIds: [...props.segmentLogic.segmentIds, props.segmentOptions[0].id]
      })
    }
  }

  const onSegmentGroupChanged = (toReplace: SegmentLogic, segmentLogic: SegmentLogic) => {
    if (segmentLogic.segmentIds.length === 0 && segmentLogic.logicGroups.length === 0) {
      const newLogicGroups = props.segmentLogic.logicGroups.filter(lg => lg !== toReplace)
      props.onLogicChanged?.({ ...props.segmentLogic, logicGroups: newLogicGroups })
    } else {
      props.onLogicChanged?.({ ...props.segmentLogic, logicGroups: props.segmentLogic.logicGroups.map(lg => (lg === toReplace ? segmentLogic : lg)) })
    }
  }

  const onNotClicked = () => {
    props.onLogicChanged?.({ ...props.segmentLogic, isNot: !props.segmentLogic.isNot })
  }

  return (
    <EuiPanel hasShadow={false} hasBorder={true} style={{ margin: 0 }}>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <AcSegmentOperatorItem hideOperator={props.disableOperator} hideIsNot={props.disableIsNot} hideAddGroup={(props.depth ?? 0) >= (props.maxDepth ?? 1)} onNotClick={onNotClicked} isNot={props.segmentLogic.isNot} operator={props.segmentLogic.operator} onOperatorChanged={onOperatorChanged} onAddLogicGroupClick={onAddLogicGroupClick} onAddSegmentClick={onAddSegmentClick} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {segmentRows.map((row, index) => (
            <React.Fragment key={row.key}>
              <EuiFormRow>
                <AcSegmentLogicItem
                  segment={row.segment}
                  segmentOptions={props.segmentOptions}
                  onDelete={() => onSegmentRemoved(row.key)}
                  onSegmentChanged={newId => {
                    onSegmentTargetChanged(row.key, newId)
                  }}
                />
              </EuiFormRow>
              {index < segments.length - 1 && (
                <EuiFormRow>
                  <EuiText>{props.segmentLogic.operator}</EuiText>
                </EuiFormRow>
              )}
            </React.Fragment>
          ))}
          {props.segmentLogic.logicGroups.length > 0 && segments.length > 0 && (
            <React.Fragment>
              <EuiFormRow>
                <EuiText>{props.segmentLogic.operator}</EuiText>
              </EuiFormRow>
              <EuiSpacer size={'m'} />
            </React.Fragment>
          )}
          {props.segmentLogic.logicGroups.map((group, index) => (
            <React.Fragment key={index}>
              <SegmentGroup
                key={index}
                segmentLogic={group}
                segmentOptions={props.segmentOptions}
                onLogicChanged={logic => {
                  onSegmentGroupChanged(group, logic)
                }}
                depth={props.depth !== undefined ? props.depth + 1 : undefined}
                maxDepth={props.maxDepth}
              />
              {index < props.segmentLogic.logicGroups.length - 1 && (
                <React.Fragment>
                  <EuiSpacer size={'m'} />
                  <EuiFormRow>
                    <EuiText>{props.segmentLogic.operator}</EuiText>
                  </EuiFormRow>
                  <EuiSpacer size={'m'} />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

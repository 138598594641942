import React, { useEffect } from 'react'

import { EuiLoadingSpinner } from '@elastic/eui'

import { SegmentLogic } from 'api'
import { useGetSegmentsQuery } from 'api/rtkQueryApi/opsApi/audiencesApi'
import { SegmentGroup } from 'components/Collections/SegmentGroup'
import { GetSegmentIds } from 'utils/segmentLogicUtils'

export interface ISegmentGrouper {
  segmentLogic: SegmentLogic
  onLogicChanged?: (segmentLogic: SegmentLogic) => void
}

export const SegmentGrouper: React.FC<ISegmentGrouper> = props => {
  const [segmentIds, setSegmentIds] = React.useState<string[]>([])
  const segmentsQuery = useGetSegmentsQuery({ segmentIds: segmentIds }, { skip: segmentIds.length === 0 })

  useEffect(() => {
    const ids = GetSegmentIds(props.segmentLogic)
    if (segmentIds.length === ids.length && segmentIds.every((value, index) => value === ids[index])) {
      return
    }

    setSegmentIds(ids)
  }, [props.segmentLogic])

  const onLogicChanged = (segmentLogic: SegmentLogic) => {
    props.onLogicChanged?.(segmentLogic)
  }

  if (segmentsQuery.isLoading) {
    return <EuiLoadingSpinner />
  }

  return props.segmentLogic ? (
    <React.Fragment>
      {segmentsQuery.isFetching && <EuiLoadingSpinner />}
      <SegmentGroup depth={0} maxDepth={1} segmentLogic={props.segmentLogic} segmentOptions={segmentsQuery.data ?? []} onLogicChanged={onLogicChanged} />
    </React.Fragment>
  ) : null
}

import { Campaign, CampaignBidder, CampaignMigrationResponse, CampaignBidderX, GeoLocationTarget, IDemographics, UpdateAdvancedTargeting, Audience, BidStrategy } from 'api/entities/Campaign'
import { adsApi } from 'api/rtkQueryApi/opsApi/adsApi'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'
import { CampaignSetInternalStatusModel } from 'api/rtkQueryApi/requestModels/CampaignSetInternalStatusModel'
import { createQueryParams } from 'utils/createQueryParams'

export interface IGetCampaignsQuery {
  organizationId?: string
  accountId?: string
  query?: string
}

export enum LocationTargetingType {
  USA = 'USA',
  State = 'State',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Map = 'Map',
  Address = 'Address'
}

const campaignsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['Campaigns', 'RetargetingCampaigns', 'Campaign', 'CampaignBidder', 'CampaignBidderX', 'ActiveCampaigns', 'EscalatedCampaigns'] })

export const campaignsApi = campaignsApiTags.injectEndpoints({
  endpoints: build => ({
    getCampaigns: build.query<Campaign[], IGetCampaignsQuery>({
      query: args => {
        const url = 'campaigns'
        const query = createQueryParams(args)

        if (query && query.length > 0) {
          return `${url}?${query}`
        }

        return url
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, arg) => (result ? [...result.map(({ campaignId }) => ({ type: 'Campaign' as const, id: campaignId })), { type: 'Campaigns', id: `${arg.organizationId}|${arg.accountId}|${arg.query}` }] : [{ type: 'Campaigns', id: `${arg.organizationId}|${arg.accountId}|${arg.query}` }])
    }),
    getCampaign: build.query<Campaign, string>({
      query: id => `campaigns/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Campaign', id }]
    }),
    getAccountRetargetingCampaigns: build.query<Campaign[], string>({
      query: accountId => `campaigns/${accountId}/retargetingCampaigns`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, accountId) => [{ type: 'RetargetingCampaigns', accountId }]
    }),
    getCampaignBidder: build.query<CampaignBidder, string>({
      query: id => `campaigns/${id}/bidder`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'CampaignBidder', id }]
    }),
    getCampaignBidderX: build.query<CampaignBidderX, string>({
      query: id => `campaigns/${id}/bidderx`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'CampaignBidderX', id }]
    }),
    getActiveCampaigns: build.query<Campaign[], IGetCampaignsQuery>({
      query: args => {
        let url = 'campaigns/active'
        const query = createQueryParams(args)

        if (query && query.length > 0) {
          return `${url}?${query}`
        }

        return url
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, arg) => [{ type: 'ActiveCampaigns', id: `${arg.organizationId}|${arg.accountId}|${arg.query}` }]
    }),
    getEscalatedCampaigns: build.query<Campaign[], void>({
      query: () => 'campaigns/escalated',
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'EscalatedCampaigns' }]
    }),
    setCampaignBidStrategy: build.mutation<boolean, { campaignId: string; bidSettings: BidStrategy }>({
      query: query => {
        return {
          url: `campaigns/${query.campaignId}/setBidSettings`,
          method: 'POST',
          body: query.bidSettings
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, query) => [{ type: 'Campaign', id: query.campaignId }]
    }),
    setCampaignInternalStatus: build.mutation<Campaign, CampaignSetInternalStatusModel>({
      query: query => {
        return {
          url: `campaigns/${query.campaignId}/setInternalStatus`,
          method: 'POST',
          body: {
            internalStatus: query.internalStatus
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, query) => [{ type: 'EscalatedCampaigns' }, { type: 'Campaign', id: query.campaignId }]
    }),
    updateCampaignXandrTargeting: build.mutation<Campaign, { campaignId: string; locationTarget: GeoLocationTarget; locationType?: LocationTargetingType; demographics?: IDemographics }>({
      query: query => {
        return {
          url: `campaigns/${query.campaignId}/updateXandrTargeting`,
          method: 'POST',
          body: {
            locationType: query.locationType,
            locations: query.locationTarget,
            demographics: query.demographics
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, query) => [{ type: 'Campaign', id: query.campaignId }]
    }),
    refreshCampaignStatus: build.mutation<Campaign, string>({
      query: campaignId => {
        return {
          url: `campaigns/${campaignId}/refreshStatus`,
          method: 'GET'
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, campaignId) => [{ type: 'Campaign', id: campaignId }]
    }),
    migrateToXandr: build.mutation<CampaignMigrationResponse, string>({
      query: campaignId => {
        return {
          url: `campaigns/${campaignId}/migrateToXandr`,
          method: 'POST'
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, campaignId) => [{ type: 'Campaign', id: campaignId }]
    }),
    revertXandrMigration: build.mutation<Campaign, string>({
      query: campaignId => {
        return {
          url: `campaigns/${campaignId}/revertXandrMigration`,
          method: 'POST'
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, campaignId) => [{ type: 'Campaign', id: campaignId }]
    }),
    reactivateCampaign: build.mutation<Campaign, { campaignId: string; paymentMethodId: string }>({
      query: query => {
        return {
          url: `campaigns/${query.campaignId}/reactivateCampaign?paymentMethodId=${query.paymentMethodId}`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn
    }),
    updateAdvancedTargeting: build.mutation<Campaign, { campaignId: string; targeting: UpdateAdvancedTargeting }>({
      query: ({ campaignId, targeting }) => {
        return {
          url: `campaigns/${campaignId}/advancedTargeting`,
          method: 'PUT',
          body: targeting
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { campaignId }) => [{ type: 'Campaign', id: campaignId }]
    }),
    updateCampaignAudience: build.mutation<Campaign, { campaignId: string; audience: Audience }>({
      query: ({ campaignId, audience }) => {
        return {
          url: `campaigns/${campaignId}/updateAudience`,
          method: 'POST',
          body: audience
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { campaignId }) => [{ type: 'Campaign', id: campaignId }]
    }),
    copyTargeting: build.mutation<Campaign, { copyToCampaignId: string; copyFromCampaignId: string }>({
      query: ({ copyToCampaignId, copyFromCampaignId }) => {
        return {
          url: `campaigns/${copyToCampaignId}/copyTargeting`,
          method: 'POST',
          body: {
            copyFromCampaignId: copyFromCampaignId
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { copyToCampaignId }) => [{ type: 'Campaign', id: copyToCampaignId }]
    }),
    convertToDisplay: build.mutation<Campaign, string>({
      query: campaignId => {
        return {
          url: `campaigns/${campaignId}/convertToDisplay`,
          method: 'POST'
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, campaignId) => [{ type: 'Campaign', id: campaignId }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        dispatch(adsApi.util.invalidateTags([{ type: 'Ads', id: result?.data?.campaignId }]))
      }
    })
  }),
  overrideExisting: false
})

export const {
  useMigrateToXandrMutation,
  useCopyTargetingMutation,
  useUpdateCampaignAudienceMutation,
  useUpdateAdvancedTargetingMutation,
  useGetAccountRetargetingCampaignsQuery,
  useRevertXandrMigrationMutation,
  useUpdateCampaignXandrTargetingMutation,
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useGetCampaignBidderXQuery,
  useGetCampaignBidderQuery,
  useGetActiveCampaignsQuery,
  useGetEscalatedCampaignsQuery,
  useSetCampaignInternalStatusMutation,
  useRefreshCampaignStatusMutation,
  useReactivateCampaignMutation,
  useSetCampaignBidStrategyMutation,
  useConvertToDisplayMutation
} = campaignsApi

import { SegmentLogic } from 'api'

export const GetSegmentIds = (segmentLogic: SegmentLogic): string[] => {
  const segmentIds: string[] = []
  if (segmentLogic) {
    if (segmentLogic.segmentIds) {
      segmentIds.push(...segmentLogic.segmentIds.map(segment => segment.toString()))
    }

    if (segmentLogic.logicGroups) {
      segmentLogic.logicGroups.forEach(logicGroup => {
        segmentIds.push(...GetSegmentIds(logicGroup))
      })
    }
  }
  // remove duplicates
  return segmentIds.filter((value, index, self) => self.indexOf(value) === index)
}

import React from 'react'

import { EuiFormRow } from '@elastic/eui'

import { Audience, CampaignType, SegmentLogic, SegmentTarget } from 'api'
import { IAcSearchSelectorItem } from 'components/ACTools/AcSearchSelector'
import { SegmentFinder } from 'components/Finders/SegmentFinder'
import { IAcTargeterDetailedItem } from 'components/Targeters/AcTargeterDetailed'
import { CuratedAudienceFinder } from 'features/customers/campaigns/targeting/CuratedAudienceFinder'
import { CuratedAudienceTargeter } from 'features/customers/campaigns/targeting/CuratedAudienceTargeter'
import { SegmentGrouper } from 'features/customers/campaigns/targeting/SegmentGrouper'

export enum AudienceType {
  CuratedAudience = 'CuratedAudience',
  SegmentLogic = 'SegmentLogic'
}

export interface IModernAudienceTargeting {
  campaignType: CampaignType
  audience: Audience
  audienceType: AudienceType
  onAudienceChange?: (audience: Audience) => void
}

export const ModernAudienceTargeting: React.FC<IModernAudienceTargeting> = props => {
  const onLogicChanged = (segmentLogic: SegmentLogic) => {
    props.onAudienceChange?.({ ...props.audience, segmentLogic })
  }

  const onSegmentSelected = (segment: SegmentTarget) => {
    props.onAudienceChange?.({ ...props.audience, segmentLogic: { ...props.audience.segmentLogic, segmentIds: [...props.audience.segmentLogic.segmentIds, segment.id] } })
  }

  const onAudienceRemove = (item: IAcTargeterDetailedItem) => {
    props.onAudienceChange?.({ ...props.audience, curatedAudienceIds: props.audience.curatedAudienceIds.filter(id => id !== item.key) })
  }

  const onAudienceSelected = (item: IAcSearchSelectorItem) => {
    props.onAudienceChange?.({ ...props.audience, curatedAudienceIds: [item.key] })
  }

  return (
    <React.Fragment>
      {props.audienceType === AudienceType.SegmentLogic && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'Audience Segments'}>
            <SegmentFinder isInvalid={false} onSegmentClicked={onSegmentSelected} mode={props.campaignType === CampaignType.Internet ? 'Internet' : 'Tv'} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <SegmentGrouper segmentLogic={props.audience.segmentLogic} onLogicChanged={onLogicChanged} />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.audienceType === AudienceType.CuratedAudience && (
        <React.Fragment>
          <EuiFormRow fullWidth>
            <CuratedAudienceFinder onCuratedAudienceClicked={onAudienceSelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <CuratedAudienceTargeter curatedAudienceIds={props.audience.curatedAudienceIds} onAudienceRemove={onAudienceRemove} />
          </EuiFormRow>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
